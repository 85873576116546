import { Button, Col, Divider, Row } from "antd"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Link } from "gatsby"
import React from "react"

export default function CompanyInfo() {
  const companyInfo = [
    { label: "会社名フリガナ", data: ["ナメタロウカブシキガイシャ"] },
    { label: "住所", data: ["東京都品川区一丁目1-1-1"] },
    { label: "法人設立年月", data: ["2000 年　3 月"] },
    { label: "代表者指名", data: ["山田　浩二"] },
    { label: "代表者指名フリガナ", data: ["ヤマダ　コウジ"] },
    { label: "ご担当者指名", data: ["山田　太郎"] },
    { label: "ご担当者指名フリガナ", data: ["ヤマダ　タロウ"] },
    { label: "電話番号", data: ["000 - 3333 - 5555"] },
    { label: "FAX番号", data: ["000 - 3333 - 5555"] },
    { label: "ご担当者メールアドレス", data: ["nametaro@nametaro.com"] },
    { label: "企業HP URL", data: ["www.nametaro.com"] },
    {
      label: "出荷場所都道府県",
      data: ["東京神奈川県横浜市1-53-3", "新潟市中央区二丁目1-53-3"],
    },
  ]
  return (
    <div>
      <Content>
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <Title
            level={3}
            style={{
              marginBottom: "1rem",
              padding: "20px 25px",
            }}
          >
            なめたろう株式会社
          </Title>
          {companyInfo.map((item, i) => {
            return (
              <Row>
                <Col span={6} style={{ color: "#888888" }}>
                  {item.label}
                </Col>
                <Col span={16} style={{ fontWeight: "bold" }}>
                  {item.data.map((item) => {
                    return <div>{item}</div>
                  })}
                </Col>
                <Divider plain></Divider>
              </Row>
            )
          })}
          <Row>
            <Col span={12} offset={10}>
              <Button
                style={{
                  backgroundColor: "#E4E8EB",
                  fontWeight: "bold",
                }}
              >
                前のページに戻る
              </Button>
              <Link to="./register">
                <Button
                  style={{
                    fontWeight: "bold",
                    marginLeft: "2rem",
                  }}
                  type="primary"
                >
                  Change Company Info
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </Content>
    </div>
  )
}
